.cookie-banner {
  max-width: clamp(300px, 80%, 600px);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: clamp(15px, 2vw, 20px) clamp(20px, 3vw, 30px);
  gap: clamp(10px, 1.5vw, 13px);
  position: fixed;
  bottom: clamp(10px, 2vw, 20px);
  right: clamp(10px, 2vw, 20px);
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.06);
  z-index: 999;
}

#cookieSvg {
  width: clamp(30px, 5vw, 50px);
}

.cookieHeading {
  font-size: clamp(1rem, 2vw, 1.2em);
  font-weight: 800;
  color: rgb(26, 26, 26);
}

.cookieDescription {
  text-align: center;
  font-size: clamp(0.8rem, 1vw, 1em);
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.cookieDescription a {
  color: rgb(59, 130, 246);
}

.cookieDescription a:hover {
  text-decoration-line: underline;
}

.cookie-categories {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: clamp(0.4em, 1vw, 0.6em);
  width: 100%;
}

.cookie-categories label {
  display: flex;
  align-items: center;
  gap: clamp(0.2em, 0.5vw, 0.4em);
}

.buttonContainer {
  display: flex;
  gap: clamp(10px, 3vw, 20px);
  flex-direction: row;
}

.buttonContainer button {
  padding: clamp(0.3em, 1vw, 0.5em) clamp(0.8em, 2vw, 1em);
}

.acceptButton {
  background-color: #004DBC;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #004ebc31, 0 2px 4px -1px #004ebc55;
  transition: all 0.6s ease;
}

.acceptButton:hover {
  background-color: #009FD5;
  box-shadow: 0 10px 15px -3px #004ebc31, 0 4px 6px -2px #004ebc31;
}

.declineButton {
  background-color: #e0e0e0;
  color: rgb(60, 60, 60);
  border: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
}

.declineButton:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.1);
}
